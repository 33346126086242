import { useEffect, useState } from 'react';
import { Row, Col, Input } from 'react-onsenui';
import { useNavigate } from "react-router-dom";
import { notification } from 'antd';
import { Content } from '../../Templates'
import Title from '../../components/Title';
import Button from '../../components/Button';
import Logo from '../../components/Logo';
import Paragraph from './../../components/Paragraph';
import { useTranslation } from "react-i18next";

export function EnrollCode({ kernel }) {

    const [code, setCode] = useState('');
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate()

    useEffect(() => {
        setName(kernel.api?.deviceName ?? '');
    }, [])

    const useCode = async () => {
        setLoading(true)
        if (kernel.api.token) {
            
            const dataUpdate = {
                name
            };
            const ret = await kernel.api.post(`/dohzel/device/change`, dataUpdate);
            if (!ret || ret.error) {
                // notification.error({
                //     message: 'Error updating device',
                //     description: res.error
                // });
                // return;
            }
            
            const data = {
                code
            };

            const res = await kernel.api.post(`/dohzel/device/joinProfile`, data);
            if (!res || res.error) {
                notification.error({
                    message: 'Error connecting profile',
                    description: res.error
                });
                setLoading(false)
                return;
            }

            notification.success({
                placement: 'bottomRight',
                message: t('EnrollCode.successMessage'),
            })            
            setLoading(false);
            return navigate('/dashboard')
        }

        const response = await kernel.api.post('/dohzel/profile/enroll', {code})
        if (response.error) {
            notification.error({
                message: `An error has occurred`,
                description: <>{response.error}</>
            })
            return
        }
        setLoading(false)
        switch (response.data.type) {
            case "unknown": 
                notification.error({
                    placement: 'bottomRight',
                    message: t('EnrollCode.failedMessage'),
                })
                navigate('/install/invalidCode')
            break;

            case 'mdm': 
                notification.success({
                    placement: 'bottomRight',
                    message: t('EnrollCode.successMessage'),
                })
                localStorage.setItem('installProcess', JSON.stringify({ codeType: 'mdm', noCode: false, code, name }));
                navigate('/install/mdm', {
                    state: {
                        profileName: response.data?.profile
                    }
                  });
            break;

            case 'free': 
                notification.success({
                    placement: 'bottomRight',
                    message: t('EnrollCode.successMessage'),
                })
                localStorage.setItem('installProcess', JSON.stringify({ codeType: 'free', noCode: false, code, name }));
                navigate('/install/free');
            break;
        }
    }

    const { t, i18n, ready } = useTranslation();
  
    if (ready) {
        return (
            <Content back={localStorage?.getItem('enrollCodeBack') ?? 'PREVIOUS'} backTransition="outRight" topAlign={true}>
                <div className='container' id="page" style={{...styles.container, height:'90dvh', width:'90dvw', paddingTop: 40}}>
                    <div>
                        <Logo />                    
                        <Title centered>{t('EnrollCode.title')}</Title>
                        
                        <div style={styles.content}>
                            <Paragraph centered>
                                {t('EnrollCode.content')}
                            </Paragraph>
                            <div className='form-container'>
                                <Paragraph>{t('EnrollCode.deviceFormName')}</Paragraph>
                                <Input
                                    autoFocus
                                    defaultValue={name}
                                    value={name}
                                    style={{color: '#FFFFFF',fontSize: 50, height: 60, display:'block', marginBottom: 20}}
                                    onChange={(event) => { setName(event.target.value) } }
                                    //modifier='material'
                                    placeholder={t('EnrollCode.deviceFormName')}
                                />
                                <Paragraph>{t('EnrollCode.deviceFormCode')}</Paragraph>
                                <Input
                                    autoFocus
                                    style={{color: '#FFFFFF',fontSize: 50, height: 60, display:'block'}}
                                    onChange={(event) => { setCode(event.target.value) } }
                                    //modifier='material'
                                    placeholder={t('EnrollCode.deviceFormCode')}
                                />
                                <div style={styles.buttonContent}>
                                <div style={styles.button}>
                                    <Button onClick={() => {
                                            navigate(localStorage?.getItem('enrollCodeBack') ?? navigate(-1))
                                        }
                                    } modifier="cta">
                                        {t('EnrollCode.cancelButton')}
                                    </Button>
                                </div>
                                <div style={styles.button}>
                                    {
                                        loading ? 
                                            <ons-icon
                                                style={{ fontSize: 50}}
                                                icon="md-spinner"
                                                spin>
                                            </ons-icon>
                                        :
                                            <Button onClick={useCode} modifier="cta" style={{paddingLeft: 20, paddingRight: 20}}>
                                                {t('EnrollCode.useButton')}
                                            </Button>
                                    }

                                </div>
                            </div>
                            </div>
                            
                        </div>
                        
                    </div>
                </div>
            </Content>
        )
    }
    return <span>{t('Loading')}</span>;
}

const styles = {
    container: {
        // margin: '25px 0'
        //display: 'flex',
        //flexDirection: 'column',
        //justifyContent: 'center',
        //alignItems: 'center'
    },
    input: {
        color: '#FFFFFF',
        fontSize: 40,
    },
    content: {
        fontSize: 30,
        marginTop: 35,
        marginBottom: 20,
        display: 'flex', 
        flexDirection: 'column',
            justifyContent: 'center',
    alignItems: 'center'
    },
    buttonContent: {
        display: 'flex',
        justifyContent: 'space-between',
        margin:'20px 0 0'
    },
    questionContainer: {
    },
    question: {
        backgroundColor: '#FFFFFF',
        color: '#000000',
        fontSize: 18,
        padding: 5
    },
    button: {
        margin: 10,
        // marginLeft: 50,
        // marginRight: 50
    }
}