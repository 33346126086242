import { useState, useEffect } from 'react';
import { ProgressCircular } from 'react-onsenui';
import { useNavigate } from "react-router-dom";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { CopyOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import { Transition } from '../../App';
import { Content } from '../../Templates';
import Title from '../../components/Title';
import Paragraph from '../../components/Paragraph';
import Button from '../../components/Button';
import Logo from '../../components/Logo';
import SmallText from '../../components/SmallText';
import copy from 'copy-to-clipboard';
import { useTranslation, Trans } from "react-i18next";

export function Device({ kernel }) {

    const [loaded] = useState(true)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();

    const installProcess = JSON.parse(localStorage.getItem('installProcess') || '{}');

    var data = {
        joinToken: kernel.config.joinToken
    }

    useEffect(() => {
        const codeType = installProcess?.codeType;
        data.name = installProcess?.name;

        if (installProcess?.code) {
            data.code = installProcess?.code;
        }
        data.security = installProcess.tags;
        data.tags = installProcess.tags;
        if (!installProcess?.noCode && installProcess?.logToProfile) {
            data.logToProfile = installProcess?.logToProfile;
        }
        if (!codeType || (codeType == 'free' && (!data.tags || (!installProcess?.noCode && !data.logToProfile))) || (codeType == 'mdm' && !data.code)) {
            navigate("/install/welcome");
        }
    }, [loaded])

    async function enroll() {
        const deviceToken = localStorage.getItem("deviceToken")
        const dohToken = localStorage.getItem("dohToken")
        
        if (!deviceToken || !dohToken) {
            setLoading(true)
            
            const ret = await kernel.api.post('/dohzel/device/enroll', data)
            if (ret.error) {
                notification.warning({
                    message: t('Device.errorEnroll'),
                    placement: 'bottomRight',
                    description: ret.error,
                });
                setTimeout(() => {
                    setLoading(false)
                }, 500)
            }
            else {
                notification.success({
                    placement: 'bottomRight',
                    message: t('Device.successEnroll'),
                });
                localStorage.setItem("deviceToken", ret.data.token)
                localStorage.setItem("dohToken", ret.data.doh)
                kernel.api.token = ret.data.doh;

                if (kernel.device.browser.device && kernel.device.androidApp === true) {
                    kernel.device.android.settingsCopyDoh(`${ret.data.doh}.${kernel.config.dot?.split('/')?.[0]}`)
                    notification.success({placement: 'bottomRight',message: t('Device.successCopied')})
                }

                if (ret.data.doh && kernel.config.dot && navigator?.clipboard && kernel.device.iosApp !== true) {
                    navigator?.clipboard?.writeText(`${ret.data.doh}.${kernel.config.dot?.split('/')?.[0]}`);
                    notification.success({placement: 'bottomRight',message: t('Device.successCopied')});
                    setLoading(false);
                }

                // if (kernel.device.iosApp === true) {
                //     await downloadMobileconfig()
                // }

                await kernel.api.beat(true);

                setLoading(false);
            }
        }
    }

    useEffect(() => {

        Transition(['#page'], () => { }, "inFade", 2000)

        // contact
        var stop = false
        var timer = null
        async function checkLastContact() {
            timer = null
            // check by ios app
            if (kernel.device.iosApp === true) {
                const isInstalled = await kernel.device.ios.settingsIsInstalled()
                if (!isInstalled)
                    await kernel.device.ios.settingsSetDoH(kernel.api.doh)

                const isActivated = await kernel.device.ios.settingsIsActivated()
                if (isActivated === true && isInstalled === true && kernel.api.token && kernel.api.lastContact > 0) {
                    setLoading(true)
                    setTimeout(() => {
                        Transition(['#page'], () => navigate("/dashboard"), "outLeft")
                    }, 1000)
                    return
                }
            }

            // check by android app
            if (kernel.device.androidApp === true) {
                const isInstalled = await kernel.device.android.settingsIsInstalled()
                // if (!isInstalled)
                //     await kernel.device.ios.settingsSetDoH(kernel.api.doh)

                const isActivated = await kernel.device.android.settingsIsActivated()

                if (isActivated === true && isInstalled === true && kernel.api.token && kernel.api.lastContact > 0) {
                    setLoading(true)
                    setTimeout(() => {
                        Transition(['#page'], () => navigate("/dashboard"), "outLeft")
                    }, 1000)
                    return
                }
            }

            // or check by last contact
            if (kernel.api.lastContact > 0 && !kernel.device.iosApp === true && !kernel.device.androidApp === true) {
                setLoading(true)
                return (Transition(['#page'], () => navigate("/dashboard"), "outLeft"))
            }

            timer = setTimeout(checkLastContact, 1000)
        }
        timer = setTimeout(checkLastContact)
        return (() => {
            stop = true
            if (timer) clearTimeout(timer)
        })
    }, [kernel]);

    async function iosEnroll() {
        await enroll();
        if (kernel.device.iosApp === true) {
            kernel.device.ios.settingsOpen()
        }
        openSettings();
    }

    function openSettings() {
        if (kernel.device.iosApp === true) {
            kernel.device.ios.settingsOpen()
        }
    }


    async function downloadMobileconfig() {
        setLoading(true)
        await enroll();
        const ret = await kernel.api.stream('/dohzel/device/mobileconfig')
        if (!ret) {
            notification.warning({
                message: t('Device.errorGettingFile'),
                placement: 'bottomRight',
                description: ret.error,
            });
        }
        else {
            const url = window.URL.createObjectURL(ret);
            window.location.href = url
            window.URL.revokeObjectURL(url);
            await openSettings()
        }
        setLoading(false);
    }

    async function openAndroidSettings() {
        setLoading(true)
        if (kernel.api.dot) {
            kernel.device.android.settingsCopyDoh(kernel.api.dot?.split('/')?.[0])
            notification.success({placement: 'bottomRight',message: t('Device.successCopied')});
            setLoading(false);
        }
        else {  
            await enroll();
        }
        // copyDot();
        if (kernel.device.androidApp === true)
            kernel.device.android.settingsOpen()
    }

    async function copyDohUrl() {
        setLoading(true)
        if (kernel.api.dot && navigator?.clipboard) {
            navigator?.clipboard?.writeText(kernel.api.dot);
            notification.success({placement: 'bottomRight',message: t('Device.successCopied')});
            setLoading(false);
        }
        else {  
            await enroll();
        }
    }

    // function copyDot() {
    //     if(kernel.api.dot) {
    //         copy(kernel.api.dot); 
    //     }
    //     notification.success({placement: 'bottomRight',message: 'Copied'})
        
    // }

    const { t, i18n, ready } = useTranslation();
    
    if (ready) {
        return (
            <Content back={installProcess.noCode ? '/install/tag' : '/install/logs'} backTransition="outRight" topAlign={true}>
                <div id="page" style={{ overflowY: 'scroll', maxHeight: '90vh'}}>
                    <div className='container' style={styles.container}>
                        <Logo /> 
                        {/* <div style={styles.title}>
                            <Title centered>Install for Apple iOS</Title>
                        </div> */}

                        <div style={styles.content}>
                        {kernel.device.browser.device.brand === "Apple" && kernel.device.iosApp !== true ?
                        <>
                            <Title centered bold>{t('Device.title', {osName: `Apple ${kernel.device.browser.os.name}`})}</Title>
                            {/* For Mac */}
                            {kernel.device.browser.os.name === "Mac" ?
                                <>
                                    <div style={{ paddingTop: 30, paddingBottom:30, textAlign: "left" }}>
                                        <ol className="steps">
                                            <li><Trans i18nKey="Device.macStep1"></Trans></li>
                                            <li><Trans i18nKey="Device.macStep2"></Trans></li>
                                            <li><Trans i18nKey="Device.macStep3"></Trans></li>
                                            <li><Trans i18nKey="Device.macStep4"></Trans></li>
                                            <li><Trans i18nKey="Device.macStep5"></Trans></li>
                                            <li><Trans i18nKey="Device.macStep6"></Trans></li>
                                        </ol>
                                    </div>
                                </>
                                : null}

                            {/* For iPhone */}
                            {kernel.device.browser.os.name === "iOS" ?
                            <ol className="steps">
                                            <li><Trans i18nKey="Device.iosWebStep1"></Trans></li>
                                            <li><Trans i18nKey="Device.iosWebStep2"></Trans></li>
                                            <li><Trans i18nKey="Device.iosWebStep3"></Trans></li>
                                            <li><Trans i18nKey="Device.iosWebStep4"></Trans></li>
                                        </ol>
                                : null}
                                {
                                    !loading ? 
                                        <Button modifier="cta" onClick={downloadMobileconfig}>
                                            {t('Device.downloadButton')}
                                        </Button>
                                    :
                                    <ProgressCircular indeterminate />
                                }

                        </>
                        : null}

                    {kernel.device.browser.device.brand === "Apple" && kernel.device.iosApp === true ?
                        <>
                            <Title centered bold>{t('Device.title', {osName: 'Apple iOS'})}</Title>
                            <ol className='steps'>
                                <li><Trans i18nKey="Device.iosAppStep1"></Trans></li>
                                <li><Trans i18nKey="Device.iosAppStep2"></Trans></li>
                                <li><Trans i18nKey="Device.iosAppStep3"></Trans></li>
                                <li><Trans i18nKey="Device.iosAppStep4"></Trans></li>
                            </ol>

                            <Button modifier="cta" loading={loading} onClick={iosEnroll} style={{
                                fontWeight: 600, color: "#001E2D",
                                backgroundColor: "transparent"
                            }}>
                                {t("Device.openButton")}
                            </Button>
                        </>
                    : null}

                    {kernel.device.browser.device && kernel.device.androidApp === true ?
                        <>
                                <Title centered bold>{t('Device.title', {osName: 'Android'})}</Title>
                                <ol className='steps'>
                                    <li><Trans i18nKey="Device.androidStep1"></Trans></li>
                                    <li><Trans i18nKey="Device.androidStep2"></Trans></li>
                                    <li><Trans i18nKey="Device.androidStep3"></Trans></li>
                                    <li><Trans i18nKey="Device.androidStep4"></Trans></li>
                                    <li><Trans i18nKey="Device.androidStep5"></Trans></li>
                                    <li><Trans i18nKey="Device.androidStep6"></Trans></li>
                                </ol>
                            
                                {
                                    loading ? 
                                    <ons-icon
                                        style={{ fontSize: 50}}
                                        icon="md-spinner"
                                        spin>
                                    </ons-icon>
                                    :
                                    <Button modifier="cta" loading={loading} onClick={openAndroidSettings} style={{
                                        fontWeight: 600, color: "#001E2D",
                                        backgroundColor: "transparent"
                                    }}>
                                        <CopyOutlined /> {t('Device.copyDownloadButton')}
                                        </Button>
                                }
                                    {/* <CopyToClipboard text={kernel.api.dot}
                                        onCopy={() => {
                                    notification.success({
                                        placement: 'bottomRight',
                                        message: 'Copied',
                                    })
                                }}>
                            </CopyToClipboard> */}
                            
                        </>
                        : null}

                    {kernel.device.browser.device.brand !== "Apple" && kernel.device.androidApp !== true ?
                        <>
                            <div style={{ padding: '20px 0', fontSize: 20 }}>
                                <Paragraph centered>
                                    {t('Device.unsupptedArchitecture')}
                                </Paragraph>                     
                            </div>
                            <div>
                                <CopyToClipboard text={kernel.api.doh}
                                    onCopy={() => {
                                        // notification.success({
                                        //     placement: 'bottomRight',
                                        //     message: 'Copied',
                                        // })
                                    }}>
                                    <Button onClick={() => copyDohUrl()} modifier="cta" loading={loading} style={{
                                        fontWeight: 600, color: "#001E2D",
                                        backgroundColor: "transparent"
                                    }}>
                                        <CopyOutlined /> {t('Device.copyButton')}
                                    </Button>
                                </CopyToClipboard>
                            </div>
                        </>
                        : null}
                        </div>
                        {/* <div style={styles.buttonContent}>
                            <div style={styles.button}>
                                {
                                    loading ? 
                                    <ons-icon
                                        style={{ fontSize: 50}}
                                        icon="md-spinner"
                                        spin>
                                    </ons-icon>
                                    :
                                    <Button modifier="cta" onClick={() => enroll()}>
                                        Open Settings
                                    </Button>
                                }
                            </div>
                        </div> */}
                        <div style={styles.description}>
                            <SmallText centered>{t('Device.noProduction')}</SmallText>
                        </div>
                    </div>
                </div>
            </Content>

        );
    }
    return <span>{t('Loading')}</span>;
}

const styles = {
    container: {
        textAlign: 'center',
    },
    title: {
        fontWeight: 600,
        fontSize: 55
    },
    input: {
        color: '#FFFFFF',
        fontSize: 40,
    },
    centerContent: {
    },
    content: { 
        margin: 'auto',
        fontSize: 30,
        margin: 10
    },
    buttonContent: {
        margin: 30,
        display: 'block'
    },
    description: {
        margin: 30
    },
    questionContainer: {
    },
    question: {
        backgroundColor: '#FFFFFF',
        color: '#000000',
        fontSize: 18,
        padding: 5
    },
    button: {
        margin: 20
    }
}