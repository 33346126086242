
import { Segmented, notification } from 'antd';
import { useEffect, useReducer, useState } from 'react';
import { Icon, Input } from 'react-onsenui';
import { useNavigate } from "react-router-dom";
import { Content } from '../../Templates';
import Title from '../../components/Title';
import '../../onsen-css-components.css';
import BigText from './../../components/BigText';
import Button from './../../components/Button';
import Paragraph from './../../components/Paragraph';
import CustomActionSheet from './ActionSheet';
import CustomAlertDialog from './AlertDialog';
import { useTranslation, Trans } from "react-i18next";

import {CopyToClipboard} from 'react-copy-to-clipboard';
import { CopyOutlined } from '@ant-design/icons';

const items = [
    {
      key: '1',
      label: 'Tab 1',
      children: 'Content of Tab Pane 1',
    },
    {
      key: '2',
      label: 'Tab 2',
      children: 'Content of Tab Pane 2',
    },
    {
      key: '3',
      label: 'Tab 3',
      children: 'Content of Tab Pane 3',
    },
  ];
  

const logs = [
    {
        name: 'Forward all logs to the profile.',
        value: 'all'
    },
    {
        name: 'Forward only security to profile.',
        value: 'onlySecurity'
    },
    {
        name: 'Do not forward to profile.',
        value: 'none'
    }
]

export function Settings({ kernel }) {
    const [loaded, setLoaded] = useState(true)
    const navigate = useNavigate()
    const [currentLogToProfil, setCurrentLogToProfil] = useState(kernel.api.logToProfile)
    const [actionSheet, setActionSheet] = useState(<></>);
    const [alertDialog, setAlertDialog] = useState(<></>);
    const [profils, setProfils] = useState([]);
    const [user, setUser] = useState({});
    const [loading, setLoading] = useState(false);
    const [connected, setConnected] = useState(false);
    const [profileName, setProfileName] = useState(kernel.api.profileName)
    const [policy, setPolicy] = useState(kernel.api.policy);
    const [deviceName, setDeviceName] = useState(kernel.api.deviceName);
    const [editDeviceName, setEditDeviceName] = useState(false);
    const [activeSegment, setActiveSegment] = useState(0);

    const [, forceUpdate] = useReducer(x => x + 1, 0);

    useEffect(() => {
        setInterval(() => {
            forceUpdate();
            kernel.api.beat()
        }, 500)
        if (!kernel.api.token)
            return (navigate("/install/welcome"))

        setLoading(true)
        var interval = null

        const loader = async () => {
            var response = await kernel.api.get("/user/me", { userRequest: true })
            if (response.error) {
                // notification.error({
                //     message: `Error fetching information`,
                //     description: <>{response.error}</>,
                //     placement: "topRight",
                // })
                // interval = setTimeout(loader, 10000);
                return
            }
            setUser(response.data);
            setLoading(false);
            setConnected(true);
        }
        loader()

        return () => {
            if (interval) clearTimeout(interval)
        }

    }, [loaded])

    useEffect(() => {
        setProfileName(kernel.api.profileName);
    }, [kernel.api.profileName, kernel.api.deviceManagement, loaded])

    useEffect(() => {
        const loadProfile = async () => {
            var response = await kernel.api.get("/dohzel/profile/me", { userRequest: true })
            if (response.error) {
                // notification.error({
                //     message: `Error fetching information`,
                //     description: <>{response.error}</>,
                //     placement: "topRight",
                // })
                return
            }
            setProfils(response.data?.list);
        }
        loadProfile()
    }, [loaded]);

    const disconnecting = () => {
        notification.success({
            message: `Disconnected`,
            description: "Your session has been closed",
        })
        kernel.session.store("");
        setAlertDialog(<></>);
        setConnected(false);
    }

    const onEdit = () => {
    }

    const handleLoaded = (loaded) => {
        setLoading(true);
        // setTimeout(() => {
        setLoaded(loaded);
        setLoading(false);
        kernel.api.beat()
        // }, 500)
    }

    async function downloadMobileconfig() {
        const ret = await kernel.api.stream('/dohzel/device/mobileconfig')
        if (!ret) {
            notification.warning({
                message: t("Dashboard.errorGettingFile"),
                placement: 'bottomRight',
                description: ret.error,
            })
        }
        else {
            const url = window.URL.createObjectURL(ret);
            window.location.href = url
            window.URL.revokeObjectURL(url);
        }
    }

    const { t, i18n, ready } = useTranslation();
    
    if (ready) {
        return (
            <Content back="/dashboard" backTransition="outRight" nofooter={true} topAlign={true} style={{justifyContent: 'start', marginTop: 40, height: 'calc(100vh - 44px)'}}>
                <div id="page" className='container' style={{ overflowY: 'scroll', maxHeight: '90vh',    width: '90%',
        maxWidth: '400px'}}>
                        
                        <div>
                            <div style={{marginBottom: 30, marginTop: 20}}>
                                <BigText centered bold>{t("Settings.title")}</BigText>
                            </div>

                            <Segmented
                                options={['Policies', 'User/Management']}
                                block
                                size='large'
                                onChange={(value) => {
                                    setActiveSegment(value==='Policies' ? 0: 1)
                                }}
                            />

                            {/* <Segment 
                                activeIndex={activeSegment}
                                modifier='outline'
                                onPostChange={(segment) => setActiveSegment(segment?.activeIndex)}
                                style={{width: '100%'}}
                            >
                                <button>Policies</button>
                                <button>User/Management</button>
                            </Segment> */}
                        </div>
                        
                    {
                            activeSegment === 0 &&
                            <>

                                {
                                    ((connected && profileName) || kernel.api.deviceManagement != 'mdm') &&
                                    <div style={{margin:'20px auto'}}>
                                        <Title 
                                        bold
                                        centered 
                                        style={{border: '0.1px solid #FFFFFF', fontSize: 25, borderRadius: 10, padding: 10}}
                                        onClick={() => {
                                            const items =  [
                                                {
                                                    name: t("Settings.blockingMode"),
                                                    value: 'blocking'
                                                },
                                                {
                                                    name: t("Settings.detectingMode"),
                                                    value: 'detecting'
                                                },
                                                {
                                                    name: t("Settings.bypassingMode"),
                                                    value: 'bypassing'
                                                }
                                            ]
                                            setActionSheet(
                                                <CustomActionSheet
                                                    title={t("Settings.policyTitle")}
                                                    cancel={() => setActionSheet(<></>)}
                                                    buttons={items}
                                                    selected={policy}
                                                    func={async (item, index) => {
                                                        const data = {
                                                            policy: item?.value
                                                        };
                                                        setLoading(true);
                                                        setPolicy(item?.value);
                                                        const ret = await kernel.api.post(`/dohzel/device/change`, data);
                                                        setLoading(false);
                                                        if (!ret || ret.error) {
                                                            notification.error({
                                                                message: t("Settings.policyErrorChange"),
                                                                description: ret.error
                                                            });
                                                            return;
                                                        }
                                                        setActionSheet(<></>);
                                                        notification.success({
                                                            message: t("Settings.policyTitle"),
                                                            description: t("Settings.policySuccessChange"),
                                                        })
                                                        handleLoaded(!loaded);
                                                    }}
                                                />)
                                        }}>{policy} mode<Icon style={{marginLeft:30}} size={{ default: 25 }} icon={'fa-caret-down'} /></Title>
                                    </div>
                                }
                                


                                <div style={{}}>
                                    {
                                    ((connected && profileName) || kernel.api.deviceManagement != 'mdm') &&
                                    <div className='setting_card' style={styles.forwarding}>
                                            <p className='setting_card_title'>{t("Settings.securityTitle")}</p>                                        
                                            <p className='setting_card_desc'>{t("Settings.securityContent")}</p>
                                            <Button
                                                    onClick={() => {
                                                        navigate('/tags')
                                                    }}
                                                    style={{margin: 'auto'}}
                                                    modifier="cta">
                                                    {t("Settings.securityButton")}
                                                </Button>
                                            {/* <Tag kernel={kernel} setLoading={setLoading} setLoaded={setLoaded} loaded={loaded} /> */}
                                        </div>
                                }

                                {
                                    ((connected && profileName) || kernel.api.deviceManagement != 'mdm') &&
                                    <div>
                                        <div className='setting_card'>
                                            <p className='setting_card_title'>{t("Settings.allowBlockTitle")}</p>
                                                <p className='setting_card_desc'>
                                                    {t("Settings.allowBlockContent")}
                                                </p>
                                                <Button
                                                        onClick={() => {
                                                            navigate('/list')
                                                        }}
                                                        style={{margin: 'auto'}}
                                                        modifier="cta">
                                                        {t("Settings.allowBlockButton")}
                                                    </Button>
                                            
                                        </div>
                                    </div>
                                }
                                </div>

                                {
                                    kernel.api.deviceManagement == 'mdm' && navigate('dashboard')
                                    // <Paragraph centered>
                                    //     Settings managed by your administrator.
                                    // </Paragraph>
                                }

                                {
                                    !kernel.api.token && navigate('install/welcome')
                                }

                                {
                                    /* 
                                        {
                                            !connected && !profileName && navigate('install/enrollCode')
                                        }   
                                    */
                                }

                            </>
                    } 

                        {
                            activeSegment === 1 &&
                            <>

                                {
                                    ((connected && profileName) || kernel.api.deviceManagement != 'mdm') &&
                                
                                        
                                        <div style={{marginTop:20}} className='form-container setting_card'>
                                            <p className='setting_card_title'>{t("Settings.deviceName")}</p>
                                                {
                                                    editDeviceName ? 
                                                        <div className='mini-form'> 
                                                            <Input
                                                                style={{width: '100%'}}
                                                                defaultValue={deviceName}
                                                                //float
                                                                onKeyup={(event) => {setDeviceName(event.target.value)}}
                                                                //modifier='material'
                                                                type='text'                                        
                                                                placeholder={t("Settings.devicePlaceholder")}
                                                            />
                                                            {
                                                                !deviceName?.length &&
                                                                <small style={styles.error}>{t("Settings.deviceNameError")}</small>
                                                            }
                                                            <Button onClick={async (e) => {
                                                                    if (deviceName?.length) {
                                                                        const data = {
                                                                            name: deviceName
                                                                        };
                                                                        setLoading(true);
                                                                        const ret = await kernel.api.post(`/dohzel/device/change`, data);
                                                                        setLoading(false);
                                                                        setEditDeviceName(false)
                                                                        if (!ret || ret.error) {
                                                                            notification.error({
                                                                                message: t("Settings.deviceErrorChangingName"),
                                                                                description: ret.error
                                                                            });
                                                                            return;
                                                                        }
                                                                    }
                                                                }}  modifier="cta" style={{marginTop:10}}>
                                                                    {t("Settings.deviceButton")}
                                                                </Button>
                                                        </div>
                                                    :
                                                        <h2 onClick={() => setEditDeviceName(true)}> {deviceName ? deviceName : 'No name'} <i className='fa fa-pencil'></i></h2>
                                                }
                                        </div>
                                }

                                {
                                    connected && profileName &&
                                    <div className='setting_card'>
                                            <p className='setting_card_title'>{t("Settings.profileTitle")}</p>
                                                <div style={{marginTop:15}}>
                                                    {
                                                        profileName && !profileName?.toLowerCase()?.includes('default profile') &&
                                                            <Paragraph>{profileName}</Paragraph>
                                                    }
                                                </div>
                                            {
                                                kernel.api.deviceManagement == 'free' &&
                                                <div style={{ marginTop: 10, display:'flex', gap:20, margin: 'auto' }}>
                                                    <Button onClick={() => {
                                                setActionSheet(
                                                    <CustomActionSheet
                                                        title={t("Settings.profileButton")}
                                                        cancel={() => setActionSheet(<></>)}
                                                        buttons={profils}
                                                        selected={profils?.find(profil => profil?.name == profileName)?.id}
                                                        func={async (item, index) => {
                                                            setLoading(true);
                                                            const ret = await kernel.api.get(`/dohzel/profile/get?id=${item?.id}`, { userRequest: true });
                                                            setLoading(false);
                                                            if (!ret || ret.error) {
                                                                // notification.error({
                                                                //     message: 'Error connecting profile',
                                                                //     description: ret.error
                                                                // });
                                                                return;
                                                            }
                                                            const data = {
                                                                code: kernel.api.deviceManagement == 'mdm' ? ret?.data?.enrollMDM : ret?.data?.enrollFREE
                                                            };
                                                            setLoading(true);
                                                            const res = await kernel.api.post(`/dohzel/device/joinProfile`, data);
                                                            setLoading(false);
                                                            if (!res || res.error) {
                                                                notification.error({
                                                                    message: t("Settings.profileErrorChange"),
                                                                    description: res.error
                                                                });
                                                                return;
                                                            }
                                                            setAlertDialog(<></>)
                                                            notification.success({
                                                                message: t("Settings.profileButton"),
                                                                description: t("Settings.profileSuccessChange"),
                                                            })
                                                            handleLoaded(!loaded);
                                                            handleLoaded(!loaded);
                                                            handleLoaded(!loaded);
                                                            setActionSheet(<></>);
                                                        }}
                                                    />
                                                )
                                            }} modifier="cta">
                                                        {t("Settings.profileButton")}
                                                    </Button>
                                                    <Button color={'red'} modifier="cta" onClick={async () => {
                                                        setAlertDialog(
                                                            <CustomAlertDialog title={t("Settings.leaveProfile")} subTitle={t("Settings.profileLeaveConfirm")} kernel={kernel} setLoading={setLoading} setLoaded={setLoaded} loaded={loaded} setActionSheet={setActionSheet} setAlertDialog={setAlertDialog}
                                                                buttons={[
                                                                    {
                                                                        label: t("NoConfirm"),
                                                                        color: 'red',
                                                                        func: () => {setAlertDialog(<></>)}
                                                                    },
                                                                    {
                                                                        label: t("YesConfirm"),
                                                                        func: async () => {
                                                                            setLoading(true);
                                                                            const ret = await kernel.api.get(`/dohzel/device/leaveProfile`);
                                                                            setLoading(false);
                                                                            if (!ret || ret.error) {
                                                                                notification.error({
                                                                                    message: t("Settings.profileLeaveError"),
                                                                                    description: ret.error
                                                                                });
                                                                                return;
                                                                            }
                                                                            setActionSheet(<></>);
                                                                            notification.success({
                                                                                message: t("Settings.leaveProfile"),
                                                                                description: "successfully leaved",
                                                                            })
                                                                            setAlertDialog(<></>);
                                                                            handleLoaded(!loaded);
                                                                            // navigate('/dashboard');
                                                                        }
                                                                    }
                                                                ]}
                                                            />
                                                        )
                                                            }}>
                                                        {t("Settings.leaveProfile")}
                                                    </Button>
                                            </div>
                                            }
                                    </div>
                                }

                                {
                                    connected && !profileName && kernel.api.deviceManagement != 'mdm' &&
                                    <div className='setting_card'>
                                            <p className='setting_card_title'>{t("Settings.profileTitle")}</p>
                                            <div style={{ margin: '20px 0'}}>
                                                <Button modifier="cta" onClick={() => {
                                                        setActionSheet(
                                                            <CustomActionSheet
                                                        title={t("Settings.connectProfile")}
                                                        cancel={() => setActionSheet(<></>)}
                                                        buttons={profils}
                                                        selected={profils?.find(profil => profil?.name == profileName)?.id}
                                                        func={async (item, index) => {
                                                            setLoading(true);
                                                            const ret = await kernel.api.get(`/dohzel/profile/get?id=${item?.id}`, { userRequest: true });
                                                            setLoading(false);
                                                            if (!ret || ret.error) {
                                                                // notification.error({
                                                                //     message: 'Error connecting profile',
                                                                //     description: ret.error
                                                                // });
                                                                return;
                                                            }
                                                            setAlertDialog(
                                                                <CustomAlertDialog title={t("Settings.profileCodeTitle")} subTitle={t("Settings.profileCodeSubTitle")} kernel={kernel} setLoading={setLoading} setLoaded={setLoaded} loaded={loaded} setActionSheet={setActionSheet} setAlertDialog={setAlertDialog}
                                                                    buttons={[
                                                                        {
                                                                            label: t("Settings.managed"),
                                                                            color: 'red',
                                                                            func: async () => {
                                                                                const data = {
                                                                                    code: ret?.data?.enrollMDM
                                                                                };
                                                                                setLoading(true);
                                                                                const res = await kernel.api.post(`/dohzel/device/joinProfile`, data);
                                                                                setLoading(false);
                                                                                if (!res || res.error) {
                                                                                    notification.error({
                                                                                        message: t("Settings.connectProfileError"),
                                                                                        description: res.error
                                                                                    });
                                                                                    return;
                                                                                }
                                                                                setAlertDialog(<></>)
                                                                                notification.success({
                                                                                    message: t("Settings.connectProfile"),
                                                                                    description: t("Settings.connectProfileSuccess"),
                                                                                })
                                                                                handleLoaded(!loaded);
                                                                            }
                                                                        },
                                                                        {
                                                                            label: t("Settings.freedom"),
                                                                            func: async () => {
                                                                                const data = {
                                                                                    code: ret?.data?.enrollFREE
                                                                                };
                                                                                setLoading(true);
                                                                                const res = await kernel.api.post(`/dohzel/device/joinProfile`, data, { deviceRequest: true });
                                                                                setLoading(false);
                                                                                if (!res || res.error) {
                                                                                    notification.error({
                                                                                        message: t("Settings.connectProfileError"),
                                                                                        description: res.error
                                                                                    });
                                                                                    return;
                                                                                }
                                                                                setAlertDialog(<></>)
                                                                                notification.success({
                                                                                    message: t("Settings.connectProfile"),
                                                                                    description: t("Settings.connectProfileSuccess"),
                                                                                })
                                                                                handleLoaded(!loaded);
                                                                            }
                                                                        }
                                                                    ]}
                                                                />
                                                            )
                                                            setActionSheet(<></>);
                                                        }}
                                                    />)
                                                    }}>
                                                        {t("Settings.connectProfile")}
                                                    </Button>
                                            </div>
                                    </div>
                                }

                                {
                                    !connected && profileName && kernel.api.deviceManagement == 'mdm' &&
                                        <div className='setting_card'>
                                            <p className='setting_card_title'>{t("Settings.profileTitle")}</p>
                                            <p className='setting_card_desc'>
                                                {t("Settings.managedProfileText")}
                                            </p>
                                        </div>
                                }

                                {
                                    !connected && profileName && kernel.api.deviceManagement == 'free' &&
                                    <div className='setting_card'>
                                            <p className='setting_card_title'>{t("Settings.profileTitle")}</p>
                                            <p className='setting_card_desc'>
                                                <Trans i18nKey="Settings.freedomProfileText"></Trans>
                                            </p>
                                            <Title 
                                            centered
                                            style={{border: '0.1px solid #FFFFFF', fontSize: 16, borderRadius: 10, padding: 10}}
                                            onClick={() => {
                                                setActionSheet(
                                                    <CustomActionSheet
                                                        title={t("Settings.logToProfile")}
                                                        cancel={() => setActionSheet(<></>)}
                                                        buttons={logs}
                                                        selected={currentLogToProfil}
                                                        func={async (item, index) => {
                                                            const data = {
                                                                logToProfile: item.value
                                                            };
                                                            setLoading(true);
                                                            const ret = await kernel.api.post(`/dohzel/device/change`, data);
                                                            setLoading(false);
                                                            if (!ret || ret.error) {
                                                                notification.error({
                                                                    message: t("Settings.logModeError"),
                                                                    description: ret.error
                                                                });
                                                                return;
                                                            }
                                                            setCurrentLogToProfil(item.value);
                                                            setActionSheet(<></>);
                                                            notification.success({
                                                                message: `Log to profile`,
                                                                description: t("Settings.logModeSuccess"),
                                                            })
                                                            handleLoaded(!loaded);
                                                        }}
                                                    />)
                                            }}> {logs.find(log => log.value == currentLogToProfil).name}<Icon style={{marginLeft:30}} size={{ default: 20 }} icon="fa-caret-down" /></Title>
                                            <div style={{ marginTop:35, display:'flex', justifyContent:'center' }}>                                  
                                                    <Button color={'red'} modifier="cta" onClick={async () => {
                                                        setAlertDialog(
                                                            <CustomAlertDialog title={'Leave your profile!'} subTitle={'Are you sure ?'} kernel={kernel} setLoading={setLoading} setLoaded={setLoaded} loaded={loaded} setActionSheet={setActionSheet} setAlertDialog={setAlertDialog}
                                                                buttons={[
                                                                    {
                                                                        label: t("NoConfirm"),
                                                                        color: 'red',
                                                                        func: () => setAlertDialog(<></>)
                                                                    },
                                                                    {
                                                                        label: t("YesConfirm"),
                                                                        func: async () => {
                                                                            setLoading(true);
                                                                            const ret = await kernel.api.get(`/dohzel/device/leaveProfile`);
                                                                            setLoading(false);
                                                                            if (!ret || ret.error) {
                                                                                notification.error({
                                                                                    message: t("Settings.profileLeaveError"),
                                                                                    description: ret.error
                                                                                });
                                                                                return;
                                                                            }
                                                                            setActionSheet(<></>);
                                                                            notification.success({
                                                                                message: t("Settings.leaveProfile"),
                                                                                description: t("Settings.profileLeaveSuccess"),
                                                                            })
                                                                            setAlertDialog(<></>);
                                                                            handleLoaded(!loaded);
                                                                        }
                                                                    }
                                                                ]}
                                                            />
                                                        )
                                                    }}>
                                                        {t("Settings.leaveProfile")}
                                                    </Button>
                                            </div>
                                        </div>
                                }
                                
                                {
                                    connected && kernel.api.deviceManagement != 'mdm' &&
                                    <div className='setting_card'>
                                            <p className='setting_card_title'>{t("Settings.accountInformation")}</p>
                                            <Paragraph>{t("Settings.accountFirstname")}: {user?.firstName}</Paragraph>
                                            <Paragraph>{t("Settings.accountLastname")}: {user?.lastName}</Paragraph>
                                            <Paragraph>{t("Settings.accountSharedToken")}: {user?.shareCode}</Paragraph>
                                            <Paragraph>{t("Settings.accountEmail")} : {user?.emails?.map((email) => (
                                                    <Paragraph>{email.email} {email.activated ? `(${t("Settings.accountEmailVerified")})` : `(${t("Settings.accountEmailNotVerified")})`}</Paragraph>
                                                ))
                                            }</Paragraph>
                                    </div>
                                }

                                {/* DOH/DOT config */}
                                {
                                    (kernel.device.browser.device && kernel.device.androidApp === true || kernel?.device?.browser?.device?.brand === "Apple" && kernel.device.iosApp !== true) &&
                                    <div>
                                        <div className='setting_card'>
                                            <p className='setting_card_title'>{t("Dashboard.configTitle")}</p>
                                                <p className='setting_card_desc'>
                                                    {t("Dashboard.configDescription")}
                                                </p>
                                                {
                                                    kernel?.device?.browser?.device?.brand === "Apple" && kernel.device.iosApp !== true ?
                                                        <Button onClick={downloadMobileconfig}>
                                                            <Icon style={{marginRight: 5}} icon={'fa-download'} /> {t("Dashboard.dohConfig")}
                                                        </Button>
                                                    : 
                                                    null
                                                }

                                                {
                                                    kernel.device.browser.device && kernel.device.androidApp === true  ?
                                                    <CopyToClipboard text={kernel.api?.dot?.split('/')?.[0]}
                                                        onCopy={() => {
                                                            notification.success({
                                                                placement: 'bottomRight',
                                                                message: 'Copied',
                                                            })
                                                        }}>
                                                        <Button style={{}}>
                                                            <CopyOutlined /> {t("Dashboard.copyDotConfig")}
                                                        </Button>
                                                    </CopyToClipboard>
                                                    : null
                                                }
                                            
                                        </div>
                                    </div>
                                }

                                {/* End of DOH/DOT config */}

                                {
                                    !connected && kernel.api.deviceManagement != 'mdm' &&
                                    <div style={{ marginTop: 20 }}>
                                            <div style={{display:'flex', margin:'30px 4px'}}>
                                                <Button
                                                    style={{width: '50%'}}
                                                    modifier="cta"
                                                    onClick={() => {
                                                        // setLoginProcess(true);
                                                        navigate('/session/login')
                                                    }}
                                                >
                                                    {t("Settings.login")}
                                                </Button>
                                                <Button 
                                                    onClick={() => {
                                                        // setLoginProcess(true);
                                                        navigate('/session/signup')
                                                    }} style={{ marginLeft: 20, width: '50%' }} modifier="cta">
                                                    {t("Settings.createAccount")}
                                                </Button>
                                            </div>

                                            <div style={{display:'flex', marginTop: 20}}>
                                        
                                                {
                                                    !connected && !profileName && kernel.api.deviceManagement != 'mdm' &&
                                                    <Button onClick={() => {
                                                            // setLoginProcess(true);
                                                            localStorage.setItem('enrollCodeBack', '/settings');
                                                            navigate('/install/enrollCode')
                                                        }} style={{ margin: 'auto' }} modifier="cta">
                                                        {t("Settings.enrollDevice")}
                                                    </Button>
                                                }
                                            </div>
                                        </div>
                                }

                                {
                                    connected && kernel.api.deviceManagement != 'mdm' &&
                                
                                        <div style={{display: 'flex', marginTop:20}}>
                                            <Button color={'red'} style={{margin: 'auto'}} modifier="cta" onClick={() => {
                                                setAlertDialog(
                                                    <CustomAlertDialog title={'Logout!'} subTitle={'Are you sure ?'} kernel={kernel} setLoading={setLoading} setLoaded={setLoaded} loaded={loaded} setActionSheet={setActionSheet} setAlertDialog={setAlertDialog}
                                                        buttons={[
                                                            {
                                                                label: "No",
                                                                color: 'red',
                                                                func: () => setAlertDialog(<></>)
                                                            },
                                                            {
                                                                label: "Yes",
                                                                func: disconnecting
                                                            }
                                                        ]}
                                                    />
                                                )
                                            }}>
                                                {t("Settings.logout")}
                                            </Button>
                                        </div>
                                }

                                {
                                    kernel.api.deviceManagement == 'mdm' && navigate('dashboard')
                                    // <Paragraph centered>
                                    //     Settings managed by your administrator.
                                    // </Paragraph>
                                }

                                {
                                    !kernel.api.token && navigate('install/welcome')
                                }

                                {
                                    /* 
                                        {
                                            !connected && !profileName && navigate('install/enrollCode')
                                        }   
                                    */
                                }

                            </>
                    } 

                        {alertDialog}
                        {actionSheet}
                    
                </div>
            </Content>
        );
    }
    return <span>{t('Loading')}</span>;
}

const styles = {
    button: {
        width: '100%',
        height: 60,
        fontSize: 20,
        marginBottom: 20,
        marginTop: 20
    },

    container: {
        color: '#FFFFFF',
        textAlign: 'center',
        fontSize: 25,
    },
    title: {
        marginTop: 80,
        fontWeight: 700,
        fontSize: 40
    },
    subTitle: {
        marginTop: 30,
        marginBottom:30,
        fontSize: 22,
        textAlign: 'center'
    },
    centerContent: {
        position: 'absolute',
        top: '30%',
        margin: 30,
    },
    content: {
        fontSize: 30
    },
    buttonContent: {
        margin: 30,
        display: 'block'
    },
    questionContainer: {
    },
    forwarding: {
        //background:'green',
        //overflowY: 'scroll',
        //overflowX: 'clip',
        fontSize: 20,
        //marginTop: 20,
        //maxWidth: 600 
    },
    question: {
        backgroundColor: '#FFFFFF',
        color: '#000000',
        fontSize: 18,
        padding: 5
    },
    error: {
        position: 'relative',
        top: -10,
        color: 'red',
        fontStyle: 'italic'
    }
}


// 2765: z-index = 9999